/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import { JSONSupport as r } from "../../core/JSONSupport.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
var t;
let p = t = class extends r {
  constructor(o) {
    super(o), this.name = null, this.code = null;
  }
  clone() {
    return new t({
      name: this.name,
      code: this.code
    });
  }
};
o([e({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "name", void 0), o([e({
  type: [String, Number],
  json: {
    write: !0
  }
})], p.prototype, "code", void 0), p = t = o([s("esri.layers.support.CodedValue")], p);
export { p as CodedValue };