/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { enumeration as o } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as a } from "../../core/accessorSupport/decorators/subclass.js";
import t from "./Domain.js";
var s;
let n = s = class extends t {
  constructor(e) {
    super(e), this.maxValue = null, this.minValue = null, this.type = "range";
  }
  clone() {
    return new s({
      maxValue: this.maxValue,
      minValue: this.minValue,
      name: this.name
    });
  }
};
e([r({
  json: {
    type: [Number],
    read: {
      source: "range",
      reader: (e, r) => r.range?.[1]
    },
    write: {
      enabled: !1,
      overridePolicy() {
        return {
          enabled: null != this.maxValue && null == this.minValue
        };
      },
      target: "range",
      writer(e, r, o) {
        r[o] = [this.minValue || 0, e];
      }
    }
  }
})], n.prototype, "maxValue", void 0), e([r({
  json: {
    type: [Number],
    read: {
      source: "range",
      reader: (e, r) => r.range?.[0]
    },
    write: {
      target: "range",
      writer(e, r, o) {
        r[o] = [e, this.maxValue || 0];
      }
    }
  }
})], n.prototype, "minValue", void 0), e([o({
  range: "range"
})], n.prototype, "type", void 0), n = s = e([a("esri.layers.support.RangeDomain")], n);
const i = n;
export { i as default };