/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import { JSONMap as t } from "../../core/jsonMap.js";
import { JSONSupport as o } from "../../core/JSONSupport.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import { Integer as i } from "../../core/accessorSupport/ensureType.js";
import "../../core/RandomLCG.js";
import "../../core/has.js";
import { enumeration as n } from "../../core/accessorSupport/decorators/enumeration.js";
import { reader as a } from "../../core/accessorSupport/decorators/reader.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import { types as l, fromJSON as p } from "./domains.js";
import { kebabDict as d } from "./fieldType.js";
var u;
const c = new t({
  binary: "binary",
  coordinate: "coordinate",
  countOrAmount: "count-or-amount",
  dateAndTime: "date-and-time",
  description: "description",
  locationOrPlaceName: "location-or-place-name",
  measurement: "measurement",
  nameOrTitle: "name-or-title",
  none: "none",
  orderedOrRanked: "ordered-or-ranked",
  percentageOrRatio: "percentage-or-ratio",
  typeOrCategory: "type-or-category",
  uniqueIdentifier: "unique-identifier"
});
let m = u = class extends o {
  constructor(e) {
    super(e), this.alias = null, this.defaultValue = void 0, this.description = null, this.domain = null, this.editable = !0, this.length = void 0, this.name = null, this.nullable = !0, this.type = null, this.valueType = null, this.visible = !0;
  }
  readDescription(e, {
    description: t
  }) {
    let o = null;
    try {
      o = t ? JSON.parse(t) : null;
    } catch (r) {}
    return o?.value ?? null;
  }
  readValueType(e, {
    description: t
  }) {
    let o = null;
    try {
      o = t ? JSON.parse(t) : null;
    } catch (r) {}
    return o ? c.fromJSON(o.fieldValueType) : null;
  }
  clone() {
    return new u({
      alias: this.alias,
      defaultValue: this.defaultValue,
      description: this.description,
      domain: this.domain?.clone() ?? null,
      editable: this.editable,
      length: this.length,
      name: this.name,
      nullable: this.nullable,
      type: this.type,
      valueType: this.valueType,
      visible: this.visible
    });
  }
};
e([r({
  type: String,
  json: {
    write: !0
  }
})], m.prototype, "alias", void 0), e([r({
  type: [String, Number],
  json: {
    write: {
      allowNull: !0
    }
  }
})], m.prototype, "defaultValue", void 0), e([r()], m.prototype, "description", void 0), e([a("description")], m.prototype, "readDescription", null), e([r({
  types: l,
  json: {
    read: {
      reader: p
    },
    write: !0
  }
})], m.prototype, "domain", void 0), e([r({
  type: Boolean,
  json: {
    write: !0
  }
})], m.prototype, "editable", void 0), e([r({
  type: i,
  json: {
    write: {
      overridePolicy: e => ({
        enabled: Number.isFinite(e)
      })
    }
  }
})], m.prototype, "length", void 0), e([r({
  type: String,
  json: {
    write: !0
  }
})], m.prototype, "name", void 0), e([r({
  type: Boolean,
  json: {
    write: !0
  }
})], m.prototype, "nullable", void 0), e([n(d)], m.prototype, "type", void 0), e([r()], m.prototype, "valueType", void 0), e([a("valueType", ["description"])], m.prototype, "readValueType", null), e([r({
  type: Boolean,
  json: {
    read: !1
  }
})], m.prototype, "visible", void 0), m = u = e([s("esri.layers.support.Field")], m);
const y = m;
export { y as default };