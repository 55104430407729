/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import { clone as e } from "../../core/lang.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import { enumeration as s } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as t } from "../../core/accessorSupport/decorators/subclass.js";
import { CodedValue as a } from "./CodedValue.js";
import c from "./Domain.js";
var d;
let p = d = class extends c {
  constructor(o) {
    super(o), this.codedValues = null, this.type = "coded-value";
  }
  getName(o) {
    let e = null;
    if (this.codedValues) {
      const r = String(o);
      this.codedValues.some(o => (String(o.code) === r && (e = o.name), !!e));
    }
    return e;
  }
  clone() {
    return new d({
      codedValues: e(this.codedValues),
      name: this.name
    });
  }
};
o([r({
  type: [a],
  json: {
    write: !0
  }
})], p.prototype, "codedValues", void 0), o([s({
  codedValue: "coded-value"
})], p.prototype, "type", void 0), p = d = o([t("esri.layers.support.CodedValueDomain")], p);
const i = p;
export { i as default };